<template>
    <div class="csv-wrap">
        <form id="msform">
          <fieldset>
            <uploadFile type="csv" @uploadFile="uploadFile" @importFile="importFile" />
          </fieldset>
        </form>
    </div>
</template>

<script>
import uploadFile from '@/components/organisms/Form/uploadFile'
import { mapState, mapGetters } from 'vuex'
export default {
    components: {
        uploadFile
    },
    computed: {
     ...mapState('files', ['uploadfileData'])
    },
    methods: {
        uploadFile(data) {
           let formData = null
           formData = new FormData()
           formData.append('files', data)
           this.$store.dispatch('files/uploadFile', formData)
        },
        importFile(data) { 
            this.$store.dispatch('files/importFile',this.uploadfileData)
        }
    }
}
</script>

<style lang="sass" scoped>
.csv-wrap
    width: 70%
    margin: 0 auto
    padding: 5rem 0
</style>